import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintStarter,
  mintTimeLimited,
  mintSupplyLimited,
  mintPixaPoly,
  claimBeam,
  claimBeamloop,//import here} from "./utils/interact.js";
  claimBeamLoop,
  checkDopplers,
  checkTL,
  checkSL,
  checkPB
} from "./utils/interact.js";
import starter from 'D:/NFT_development/front_end/new-app-pages/PixaBadges/nft-minter-tutorial-main/minter-starter-files/src/starterbadge.gif'
import TLbadge from 'D:/NFT_development/front_end/new-app-pages/PixaBadges/nft-minter-tutorial-main/minter-starter-files/src/TLBadge.gif'
import PBbadge from 'D:/NFT_development/front_end/new-app-pages/PixaBadges/nft-minter-tutorial-main/minter-starter-files/src/PBBadge.gif'
import SLbadge from 'D:/NFT_development/front_end/new-app-pages/PixaBadges/nft-minter-tutorial-main/minter-starter-files/src/SLBadge.gif'
import title from 'D:/NFT_development/front_end/new-app-pages/PixaBadges/nft-minter-tutorial-main/minter-starter-files/src/title.png'
import pixagif from 'D:/NFT_development/front_end/new-app-pages/PixaBadges/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo.gif'
import pixapng from 'D:/NFT_development/front_end/new-app-pages/PixaBadges/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo-2.png'

const Minter = (props) => {

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [timelimited, setTL] = useState("")
  const [supplylimited, setSL] = useState("")
  const [pixalimited, setPB] = useState("")
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [wizstatus, setWizStatus] = useState(""); 
  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    const {TLstatus} = await checkTL();
    const {SLstatus} = await checkSL();
    const {PBstatus} = await checkPB()
    setWallet(address);
    setStatus(status);
    setTL(TLstatus);
    setSL(SLstatus);
    setPB(PBstatus);

    addWalletListener(); 
  }, []);

  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => { //TODO: implement
      const { status } = await mintStarter();
    setStatus(status);  
  };

  const onMintTL = async () => { //TODO: implement
    const { status } = await mintTimeLimited();
  setStatus(status);  
  };

  const onMintSL = async () => { //TODO: implement
    const { status } = await mintSupplyLimited();
  setStatus(status);  
  };

  const onMintPB = async () => { //TODO: implement
    const { status } = await mintPixaPoly(walletAddress);
  setStatus(status);  
  };

  const onClaimPressed = async () => { //TODO: implement
      const { status } = await claimBeam();
  };

  const onClaimLoopPressed = async () => { //TODO: implement
      const { status } = await claimBeamLoop();
  };

  const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkDopplers(name);
    setWizStatus(wizstatus);  
  };

  return (
    <div className="Minter">
<div id="stars"></div>
<div id="stars2"></div>
<div id="stars3"></div>
<h5>By using this webpage you agree to the terms & conditions outlined at the bottom of this page. Disclaimer: Use this site at your own risk.</h5>       

<h1>
          <div>
      <img src={pixapng} alt="Image" height={45} width={100} border={0}/>
      </div>
</h1>

<div id="menubar">
      <h2> <a href="https://wizards.pixanft.com" rel="noreferrer">  🧙‍♂️</a>  <a href="https://witches.pixanft.com" rel="noreferrer">  🧙‍♀️</a> <a href="https://wyverns.pixanft.com" rel="noreferrer">  🐲</a> <a href="https://wargs.pixanft.com" rel="noreferrer">  🐺</a>  <a href="https://brews.pixanft.com" rel="noreferrer">  🍺</a> <a href="https://limitededitions.pixanft.com" rel="noreferrer">  🖼️</a> </h2>
      <h5>Click an emoji to navigate to other pages!</h5>         
      <h2> <a href="https://tokens.pixanft.com" rel="noreferrer">  💰</a>  <a href="https://relics.pixanft.com" rel="noreferrer">  🗡️</a> <a href="https://wizardao.pixanft.com" rel="noreferrer">  🎩</a> <a href="https://tomes.pixanft.com" rel="noreferrer">  📖</a> <a href="https://land.pixanft.com" rel="noreferrer">  🏡</a> <a href="https://badges.pixanft.com" rel="noreferrer">  📛</a> </h2>
</div>          


<br></br>
<br></br>

            <h4>SWITCH WALLET TO POLYGON NETWORK</h4>

      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect to Polygon</span>
        )}
      </button>
           
<br></br>

<h1>📛  The  📛</h1>
<h1> PixaBadges </h1>
<h3>_________</h3>

      <h4> Collectible souvenir badges minted on Polygon for the Pixa NFT community! There are 4 types of badges: "The Starter Badge" which is always available and free for anyone to mint; "Time-Limited Badges" where the number of mints is unlimited, but is only open for a limited time; "Supply-limited Badges" where the supply is restricted to a certain amount; and "$POLYPIXA badges", where you must spend some $PIXA (polygon) to receive the badge.  </h4>

<h4>The PixaBadges are ERC721 tokens minted on the Polygon blockchain and were released in March 2022. For more information about the other NFT collections that exist in the PixaRealm, visit our <a href="https://pixanft.com" rel="noreferrer">website</a></h4>

<h4> Explore the PixaBadge collection on <a href="https://opensea.io/collection/pixabadges" rel="noreferrer">  OpenSea</a></h4>
      <h3>_________</h3>
      <br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

      <h3>THE STARTER BADGE</h3>   
      <h3>_________</h3> 
      <h1>  <div> <img src={starter} alt="Image" height={300} width={300} style={{alignItems: 'Center'}} /> </div></h1>	
	       <button id="mintButton" onClick={onMintPressed}>
        Claim
      </button>
    <h4> Claim your free starter badge</h4>
    <h5> - Price: FREE! -</h5>
    <h5> - 1 starter badge claim per wallet, unlimited supply -</h5>


    <h3>_________</h3>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>


<h3>THE BREWFEST BADGE</h3>  
<h3>_________</h3>  
<h1> <div> <img src={TLbadge} alt="Image" height={300} width={300} /> 	</div></h1>
  
<h4> Claim your FREE Brewfest Badge available only this weekend! Happy Brewfest everyone! WE ARE NOT AFFILIATED WITH BORED BREWING OR BORED APE YACHT CLUB. JUST BIG FANS 🙌</h4>
  <button id="mintButton" onClick={onMintTL}>
 Claim
</button>

<h4> Time left to claim: {timelimited} seconds</h4>
<h5> - Price: FREE! -</h5>
<h5>- 1 badge claim per wallet, unlimited supply -</h5>


<h3>_________</h3>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>


<h3>THE MERGE DAY BADGE</h3>  
<h3>_________</h3>  
<h1><div>  <img src={SLbadge} alt="Image" height={300} width={300} /> 	</div></h1>
<h4> Claim your FREE Merge Day Badge! Celebrate the ethereum merge on 09/15! </h4> 
   <button id="mintButton" onClick={onMintSL}>
  Claim
</button>
<h4>{supplylimited} / 915 have been minted so far!</h4>
<h5> - Price: FREE! -</h5>
<h5>- 1 badge claim per wallet -</h5>


<h3>_________</h3>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>






<h3>THE JACK-O-LANTERN BADGE</h3> 
<h3>_________</h3>   
<h1><div>  <img src={PBbadge} alt="Image" height={300} width={300} /> 	</div></h1>
<h4> Claim your Jack-O-Lantern Badge! Let's celebrate spooky szn! </h4>

   <button id="mintButton" onClick={onMintPB}>
  Claim
</button>
<h4>{pixalimited}/300 have been minted so far!</h4>
<h5> - Price: 15 <a href="https://polygonscan.com/token/0xca8c6dea40a6de9be3b4c73a1aefaafdb4754f73" rel="noreferrer">$POLYPIXA</a> -</h5>
<h5>IMPORTANT: You will need to sign a token approval for the contract to spend your $PIXA (poly). Then, you will be able to mint successfully.</h5>



<h3>_________</h3>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>






      <h4>Important Links:</h4>

<h4><a href="https://polygonscan.com/address/0x029dfd5b016ed918416cd1faf53d79a013b3065c" rel="noreferrer">  PixaBadges Contract</a> </h4>

<h4><a href="https://linktr.ee/pixanft" rel="noreferrer">  Linktree</a> </h4>

<h4> <a href="https://opensea.io/PixaDeployer?tab=created" rel="noreferrer">  OpenSea Collections</a> </h4>

<h4><a href="https://discord.gg/pixa" rel="noreferrer">  Discord</a> </h4>

<h4><a href="https://twitter.com/pixa_nft" rel="noreferrer">  Twitter </a></h4>

<br></br>


<h4>Terms & Conditions:</h4>

<h9>(applies to all Pixa sites & smart contracts) - Use this site at your own risk. Pixa is not liable for any losses from the use of this site or associated smart contracts. Your purchase of Pixa NFTs does NOT CONSTITUTE AN INVESTMENT. Purchase of these NFTs from Pixa is meant for entertainment purposes only. Pixa makes no warranties regarding future value, express or implied, to the public or to buyers. Pixa does not offer refunds on purchased NFTs. Only purchase if you wish to own the artwork and nothing else. Only purchase with the current state of the project in mind and the assumption that no future value will be delivered. Pixa NFTs and $PIXA tokens are not currency and do not convey ownership in Pixa or its assets. You are purchasing a digital collectible, we do not guarantee any future development around these NFTs. $PIXA tokens are not shares or securities of any type. Holding a token does not entitle the holder to any ownership or other interest in Pixa (formerly known as PixaLyfe), future business operations, future revenue, or any control or ability to bind the entity. $PIXA tokens are merely a means by which you may be able to utilize certain services such as changing aspects of the artwork in your Pixa NFT. There is no guarantee that the PixaRealm platform will actually be developed, although we guarantee that it is our intention to do so.</h9>

<h1>
          <div>
      <img src={pixagif} alt="Image" height={200} width={200} border={0}/>
      </div>
</h1>



    </div>
  );
};



export default Minter;
