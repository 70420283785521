require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_POLYGON_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey); 

const contractABI = require('../contract-abi.json')
const contractAddress = "0x029Dfd5B016ed918416CD1fAF53d79A013b3065c";
const contractABIpixa = require('../contract-abi-pixa.json')
const contractAddresspixa = "0xca8c6dea40a6de9be3b4c73a1aefaafdb4754f73";



export const connectWallet = async () => {
    const chainID = await window.ethereum.request({
          method: "eth_chainId",
         
        });  

    if (window.ethereum && chainID == 137) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Write a message in the text-field above.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};


export const mintStarter = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(contractABI, contractAddress);

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.mintStarterBadge().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}

export const mintSupplyLimited = async() => {
  //set up your Ethereum transaction
   window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  
   const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.mintSLbadge().encodeABI()//make call to NFT smart contract 
   };
      
  //sign the transaction via Metamask
   try {
      const txHash = await window.ethereum
          .request({
              method: 'eth_sendTransaction',
              params: [transactionParameters],
          });
      return {
          success: true,
          status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
      }
   } catch (error) {
      return {
          success: false,
          status: "😥 Something went wrong: " + error.message
      }
  
   }
  
      
}







export const mintTimeLimited = async() => {
  //set up your Ethereum transaction
   window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  
   const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.mintTLbadge().encodeABI()//make call to NFT smart contract 
   };
      
  //sign the transaction via Metamask
   try {
      const txHash = await window.ethereum
          .request({
              method: 'eth_sendTransaction',
              params: [transactionParameters],
          });
      return {
          success: true,
          status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
      }
   } catch (error) {
      return {
          success: false,
          status: "😥 Something went wrong: " + error.message
      }
  
   }
  
      
}
  


export const mintPixaPoly = async(walletAddress) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  window.contract2 = await new web3.eth.Contract(contractABIpixa, contractAddresspixa);
 
   var wazaaa = await window.contract2.methods.allowance(walletAddress, contractAddress).call();
   
   if (wazaaa < 1000) {
 
   const transactionParameters1 = {
         to: contractAddresspixa, // Required except during contract publications.
         from: window.ethereum.selectedAddress, // must match user's active address.
         'data': window.contract.methods.approve(contractAddress,1000000).encodeABI()//make call to NFT smart contract 
         };
 
   const txHash = await window.ethereum
         .request({
             method: 'eth_sendTransaction',
             params: [transactionParameters1],
         });
   } 
   else {
 
   }
 
  const transactionParameters = {
         to: contractAddress, // Required except during contract publications.
         from: window.ethereum.selectedAddress, // must match user's active address.
         'data': window.contract.methods.mintPMbadge().encodeABI()//make call to NFT smart contract 
  };
     
 //sign the transaction via Metamask
  try {
     const txHash = await window.ethereum
         .request({
             method: 'eth_sendTransaction',
             params: [transactionParameters],
         });
     return {
         success: true,
         status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
     }
  } catch (error) {
     return {
         success: false,
         status: "😥 Something went wrong: " + error.message
     }
 
  }
 
     
      
}
  



export const checkTL = async(name) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var wazaaa = await window.contract.methods.TL().call();
  var time = wazaaa - Math.round(Date.now()/1000)

  if (time > 0 ) {
    var result = time;
  } 
  else {
    var result = 0;
  }
//make call to NFT smart contract 

  return {TLstatus: result}
}

export const checkSL = async(name) => {
  //set up your Ethereum transaction
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    var wazaaa = await window.contract.methods.SLtrack().call();
 
    return {SLstatus: wazaaa}
  }
  


  export const checkPB = async(name) => {
    //set up your Ethereum transaction
      window.contract = await new web3.eth.Contract(contractABI, contractAddress);
      var wazaaa = await window.contract.methods.PMtrack().call();
   
      return {PBstatus: wazaaa}
    }
    
  
  


export const checkDopplers = async(name) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var wazaaa = await window.contract.methods.doplist(name).call();
  
  if (wazaaa == true) {
    var result = "Claimed";
  } 
  else {
    var result = "Unclaimed";
  }
//make call to NFT smart contract 

  return {wizstatus: result}
}

export const claimBeam = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(contractABI, contractAddress);

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.claimBeam().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}


export const claimBeamLoop = async() => {
//set up your Ethereum transaction
 window.contract = await new web3.eth.Contract(contractABI, contractAddress);

 const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.claimBeamLoop().encodeABI()//make call to NFT smart contract 
 };
    
//sign the transaction via Metamask
 try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
        status: "✅ Txn on Etherscan: https://etherscan.io/tx/" + txHash
    }
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message
    }

 }

    
}